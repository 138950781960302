import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";

const DEFAULT_FORM_STATE = {
  name: null,
  email: null,
  profile: {},
  phone: null,
  _method: 'post',
  event_id: null
};
const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Email",
    sortable: false,
    value: 'email',
  },
  {
    text: "Phone",
    sortable: false,
    value: 'phone',
  },
  {
    text: "Deactive",
    sortable: false,
    value: 'is_deactive',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'Master Guest User',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      listUrl: '/guest/users',
      columns: COLUMN_DEFINATION(this),
      deleteId: null,
      file: {},
      dropdowns: {
        superUsers: [],
        events: [],
      },
    };
  },
  methods: {
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let self = this;
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      let self = this;

      self.formFields.profile = self.getFile;

      try {
        const response = await request({
          url: this.formFields.id ? 'guest/users/update' : 'guest/users/create',
          method: 'post',
          data: {
            ...this.formFields,
          },
        });
        const { data } = response.data;

        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/guest/users/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/guest/users/detail/${ id }`,
        });
        this.operationTitle = 'Edit User';
        const { data } = response.data;
        this.formFields = {
          ...data,
          event_id: data.events ? data.events.map(i => i.id) : [],
          property_id: data.property ? data.property.id : null
        };
        this.file = data.profile_pic;
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getSuperUsers() {
      try {
        const response = await request({
          url: '/dropdowns/super/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.superUsers = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getEvents() {
      try {
        const response = await request({
          url: '/dropdowns/events',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.events = data.map((item) => {
          return {
            id: item.id,
            label: item.title,
          };
        });

        this.selectEvent = this.dropdowns.events[0].value;
      } catch (e) {

      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "guestUserList" });
    },
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};

